
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import t from "@common/src/i18n/t";
@Component({})
export default class DataEmpty extends Vue {
  @Prop({ default: t("base.no-data") })
  description: string;
  @Prop({ default: "" })
  guideText: string;
  @Prop({ default: "" })
  guidePath: string;
}
